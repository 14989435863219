    <template>
    <div>
      <!--  @click="ActivateActiveChildComponent" -->
        <v-card flat tile class="transparent"  @click="ActivateActiveChildComponent">
         <QuickAlerts :Alerts="PrimaryTaskAlerts" v-if="!LineGraph && !BarGraph"  
                />
          <QuickCounters :Counters="PrimaryTaskCounters" v-if="!LineGraph && !BarGraph"
                />
          <QuickCharts :System="System" :SystemEntities="SystemEntities" :ChartData="MyActivitiesArray"
          :ChartObj="ActivitiesThisWeekChartObj" :i="0"
          />
          <QuickCharts :System="System" :SystemEntities="SystemEntities" :ChartData="MyActivitiesArray"
          :ChartObj="ActivitiesThisWeekChartObj" :i="0" v-if="LineGraph || !LineGraph && !BarGraph"
          />
           <QuickCharts :System="System" :SystemEntities="SystemEntities" :ChartData="MyActivitiesArray"
          :ChartObj="ActivitiesThisWeekChartObj" :i="0" v-if="BarGraph"
          />
          <!-- QuickCharts - charts closed this week (I think)  -->
        </v-card>
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/Navigation/BreadCrumbs'
import MyActivitiesbyType from '@/views/Activities/MyProfile/MyActivitiesbyType'
import AllMyTasksandAppointments from '@/views/Activities/MyProfile/AllMyTasksandAppointments'
import QuickAlerts from '@/components/Dashboards/QuickAlerts';
import QuickCounters from '@/components/Dashboards/QuickCounters';
import QuickCharts from '@/components/Dashboards/QuickCharts';
export default {
  props: ['AppisDarkMode','TeamView','MenuItem','SystemEntities','MyActivitiesArray','ChildComponent','LineGraph','BarGraph','Color'],
  components: { BreadCrumbs,AllMyTasksandAppointments,QuickAlerts,QuickCounters,QuickCharts },
  data() {
    return {
    Today: '',
    NextWeek: '',
    ActivityType: '',
    ActivitiesFullView: true,
      Icons: [],
      drawer: false,
      items: [],
    
      UserFullName: '',
      UserAssignedRoles: [],
      IsAdmin: false,
      // MyActivitiesArray: [],
      UserRecord: {},
      ActiveUser: '',
    }
  },
  computed: {
    ActivitiesThisWeekChartObj(){
      let chartobj = {
        Preset: 'Activities',
        Type: 'Line',
        TimeFilterOption: 'This Month',
        PrimaryLabelType: 'From Timeline',
        PrimaryEntity: 'Activities',
        Data: [],
        ChartLabelsfromTimeline: true,
        ChartLabels: [],
        ChartBG: 'purple',
        PrimaryDateFilter: 
            {
                Name: 'Due_Date',
                Type: 'Date'
            },
        ChartType: 'line',	
        ColumnWidth: 'Full Column',	
        ChartPosition: 'Left',
        HasData: true,
        HasChart: true,
        HasDataContext: false,
        DataContextStyle: 'List',
        Data: this.MyActivitiesArray,
        ValueType: 'Count'
      }
      chartobj.DashboardEntity = this.SystemEntities.find(obj => obj.id === 'Activities')
      if(this.BarGraph){
        chartobj.ChartType = 'bar'
        chartobj.Type = 'Bar'
      }
      if(this.Color){
        chartobj.ChartBG = this.Color
      }
      return chartobj
    },
    OverdueActivities(){
      let today = this.Today.getTime()   
      let lastdaylastweek = this.LastweekLastDate()
      let lastdaylastweektime = lastdaylastweek.getTime()   
      return this.MyActivitiesArray.filter(act => {
        return act.Type === 'Task'
      })
      .filter(act => {
        let duedate = act.Due_Date.toDate()
        duedate = duedate.getTime()
        return duedate < today
        // && duedate > lastdaylastweektime
      })
    },
    ActivitiesDueThisWeek(){
      let weekend = this.EndofWeek(this.Today)
      let weekendtime = weekend.getTime()   
      let monday = this.ThisMonday()
      let mondaytime = monday.getTime()
      return this.MyActivitiesArray.filter(act => {
        return act.Type === 'Task'
      }).filter(act => {
        let duedate = act.Due_Date.toDate()
        duedate = duedate.getTime()
        return duedate > mondaytime && duedate <= weekendtime
      })
    },
    ActivitiesDueNextWeek(){
      let weekend = this.EndofWeek(this.Today)
      let weekendtime = weekend.getTime()   
      let nextweekstartdate = this.NextweekFirstDate(this.Today) 
      let nextweekenddate =  this.EndofWeek(nextweekstartdate)
      let nextweekenddatetime = nextweekenddate.getTime()
      return this.MyActivitiesArray.filter(act => {
        return act.Type === 'Task'
      }).filter(act => {
        let duedate = act.Due_Date.toDate()
        duedate = duedate.getTime()
        return duedate > weekendtime && duedate <= nextweekenddatetime
      })
    },
    PrimaryTaskCounters(){
            return [
              // #ff980012
                {CounterIcon: 'mdi-alert',Data: this.OverdueActivities,CounterInteger: this.OverdueActivities.length, Title: 'Overdue',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#DD2C00'},FontColor: {hex: '#DD2C00'}},
                {CounterIcon: 'mdi-alert',Data: this.ActivitiesDueThisWeek,CounterInteger: this.ActivitiesDueThisWeek.length, Title: 'Due this Week',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#E65100'},FontColor: {hex: '#E65100'}},
                {CounterIcon: 'mdi-calendar',Data: this.ActivitiesDueNextWeek,CounterInteger: this.ActivitiesDueNextWeek.length, Title: 'Due Next Week',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#795548'},FontColor: {hex: '#795548'}},
                {CounterIcon: 'mdi-clipboard-list',Data: this.MyActivitiesArray,CounterInteger: this.MyActivitiesArray.length, Title: 'Open',Outlined: true,Tile: false,IsDark: false,Elevation: 0,
                BGColor: {hex: ''},HeaderColor: {hex: '#607D8B'},FontColor: {hex: '#607D8B'}},
            ]
        },
    PrimaryTaskAlerts(){
            return [
                {Title: 'Incomplete Activities',Array: this.OverdueActivities, TableName: 'Activities',IncompleteName: 'Overdue'},
            ]
        },
    },

    watch: {
      
    },

  created() {
      this.ActiveUser = this.userLoggedIn
      this.Today = new Date()
      this.Today.setHours(0)
      this.Today.setMinutes(0)
      this.Today.setSeconds(0) 
  },

  methods: {
    EndofWeek(date){			
     			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var lastdaylastweek = date.getDate() - date.getDay();			
      let d = new Date(year, month, lastdaylastweek+7)			
      d.setHours(23,59,59,999)			
      return d			
    },
    ThisMonday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+1))			
          date.setHours(0,0,0,0)			
          return date			
      },
    LastweekLastDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek))			
          date.setHours(0,0,0,0)			
          return date			
      },
    NextweekFirstDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek + 7))			
          date.setHours(0,0,0,0)			
          return date			
      },
    ThisSunday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+7))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ActivateActiveChildComponent(){
          this.$emit('ActivateActiveChildComponent',this.ChildComponent)
      },
  }

}
</script>

<style>
.shaped{
  border: 1px solid red;
  border-radius: 5px;
}
</style>
    